<template>
  <div>
    <con-tab @TwoClick="TwoClick"></con-tab>
    <!-- 服务商信息 -->
    <InfoNei></InfoNei>
  </div>
</template>

<script>
import ConTab from "../../components/common/ConTab";
import InfoNei from "./base/InfoNei";
// import Qs from 'qs'
export default {
  name: "information",
  data() {
    return {};
  },
  created() {},
  methods: {
    TwoClick(item) {
      //console.log(item);
      this.ConList = item;
    },
  },
  components: {
    ConTab,
    InfoNei,
  },
};
</script>

<style scoped>
</style>
