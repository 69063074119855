<template>
	<div class="bc">
		<div class="release w">
			<div class="release_con">
				<div class="pea-title">
					<div class="one-content">
						<div class="copy-title">
							<div class="copy-con">
								<span></span>
								<h3>注册信息</h3>
								<span></span>
							</div>
						</div>
					</div>
				</div>
				<el-row class="title">
					<span class="one">1</span>
					<span>基本信息</span>
				</el-row>
				<el-form label-width="80px" :model="info" :label-position="labelPosition">
					<el-form-item label="名称"><el-input v-model="info.name" placeholder="请填写名称"></el-input></el-form-item>
					<el-form-item label="logo">
						<!-- <div class="fb_img">
              <img src="../../../assets/img/kefuyun/fb.png" alt="" />
            </div> -->
						<el-upload
							:on-change="fn"
							class="upload-demo"
							ref="upload"
							action=""
							:file-list="fileList"
							:auto-upload="false"
							:limit="1"
							:on-remove="handleRemove"
							:on-exceed="handleExceed"
							list-type="picture"
							accept="image/*"
						>
							<el-button plain size="mini">上传</el-button>
							<div slot="tip" class="el-upload__tip">支持扩展名：.png .jpg...</div>
						</el-upload>
					</el-form-item>
					<el-form-item label="简介"><el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="info.describe"></el-input></el-form-item>
					<el-form-item label="企业规模"><el-input v-model="info.scale" placeholder="请填写企业规模"></el-input></el-form-item>
					<el-form-item label="年营业额">
						<el-input style="width: 93%; margin-right: 10px;" v-model="info.turnover" placeholder="请填写年营业额" type="number"></el-input>
						<span>万元</span>
					</el-form-item>
					<el-form-item label="注册时间">
						<!-- <el-input
              v-model="info.created_time"
              placeholder="请填写注册时间"
            ></el-input> -->
						<el-date-picker v-model="info.created_time" type="date" placeholder="请填写注册时间"></el-date-picker>
					</el-form-item>
					<el-form-item label="注册资本">
						<el-input style="width: 93%; margin-right: 10px;" v-model="info.capitals" type="number" placeholder="请填写注册资本"></el-input>
						<span>万元</span>
					</el-form-item>
					<el-form-item label="法人代表"><el-input v-model="info.legal" placeholder="请填写法人代表"></el-input></el-form-item>
					<el-form-item label="所属行业"><el-input v-model="info.industry" placeholder="请填写所属行业"></el-input></el-form-item>

					<el-form-item label="注册类型">
						<el-radio-group v-model="info.type">
							<el-radio :label="item.id" v-for="item in type" :key="item.index">{{ item.name }}</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
				<el-row class="title">
					<span class="one">2</span>
					<span>企业介绍</span>
				</el-row>
				<el-form label-width="80px" :model="info" :label-position="labelPosition">
					<el-form-item label="企业介绍"><el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="info.description"></el-input></el-form-item>
				</el-form>
				<div class="subtmit-btn-box"><el-button type="primary" @click="onRelease">提交</el-button></div>
			</div>
		</div>
	</div>
</template>

<script>
import { eType, EmployerAdd } from '../../../common/js/api';
import { uploadImg } from '../../../common/img/apiimg';
export default {
	name: 'ReleaseNei',
	data() {
		return {
			labelPosition: 'left',
			info: {
				member_id: '',
				title: '',
				phone: '',
				name: '',
				describe: '',
				process: '',
				scale: '',
				turnover: '',
				created_time: '',
				capitals: '',
				legal: '',
				industry: '',
				type: '',
				description: ''
			},
			type: [],
			fileList: [],
			id: {}
		};
	},
	created() {
		this.info.member_id = JSON.parse(localStorage.getItem('user')).id;
		this.info.title = this.$route.query.title;
		this.info.phone = this.$route.query.phone;
		this.getType();
	},
	methods: {
		// 文本域带格式
		preText(pretext) {
			return pretext
				.replace(/\r\n/g, '<br/>')
				.replace(/\n/g, '<br/>')
				.replace(/\s/g, '&nbsp;');
		},
		// 获取服务商类型
		getType() {
			eType()
				.then(res => {
					console.log(res);
					this.type = res.data;
				})
				.catch(() => {});
		},
		// 提交
		onRelease() {
			this.info.describe = this.preText(this.info.describe);
			this.info.description = this.preText(this.info.description);
			EmployerAdd(this.info)
				.then(res => {
					// console.log(res);
					if (res.code == 200) {
						this.$message.success('提交成功');
						this.$router.go(-1);
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch(() => {});
		},
		//     上传文件
		fn(file, fileList) {
			console.log(this.fileList);
			this.up(file.raw, fileList);
		},
		// 删除文件
		handleRemove(file, fileList) {
			console.log(file, fileList);
			this.up(file.raw, fileList);
		},
		handleExceed(files, fileList) {
			this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
		},
		up(file, fileList) {
			let formData = new FormData();
			formData.append('file', file);
			uploadImg(formData)
				.then(res => {
					console.log(res);
					if (res.code == 200) {
						this.$message.success('上传成功');
						this.info.img = res.data;
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch(err => {});
		}
	},
	mounted() {}
};
</script>

<style scoped lang="less">
.release .fb_img {
	display: inline-block;
	width: 100px;
	height: 100px;
	vertical-align: bottom;
}
.release .subtmit-btn-box {
	margin-top: 70px;
	display: flex;
	justify-content: center;
}

/deep/.el-form {
	padding-left: 40px;
}
/deep/.el-textarea__inner {
	resize: none;
}
/deep/.upload-demo {
	display: inline-block;
	margin-left: 20px;
}
.el-date-editor.el-input {
	width: 100%;
}
</style>
